import Header from './components/header/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contacts from './components/contacts/Contacts';
import Gallery from './components/gallery/Gallery';
import Press from './components/press/Press';
import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import { initializeApp } from 'firebase/app'
import { getStorage, ref } from "firebase/storage";
import Admin from './components/Admin/Admin';
import TokenReq from './components/Admin/TokenReq';
import TokenError from './components/Errors/TokenError';
import AdminContextProvider from './contexts/AdminContext';
// import CookieConsent from "react-cookie-consent";
// import Swal from 'sweetalert2'

function App() {
  const fbConfig = {
    projectId: "anis-jerbi-art-website",
    storageBucket: "anis-jerbi-art-website.appspot.com",
  }

  const app = initializeApp(fbConfig);
  const storage = getStorage(app)
  const storageRef = ref(storage);


  // const showCookie = () => {
  //   Swal.fire({
  //     html: `
  //     <div>
  //     **Cookie Banner and Privacy Assurance for AnisJerbi.com**
  //     <br />
  //     Welcome to AnisJerbi.com (the "Website"). We want to assure you that your privacy is our priority, and we are committed to providing a transparent and secure browsing experience.
  //     <br />
  //     **1. Your Privacy is a Priority**
  //     <br />

  //     We do not use any commercial or statistical cookies on our website. This means that you can enjoy browsing our website without any concerns about your data being used for commercial or analytical purposes.
  //     <br />

  //     **2. What are Cookies?**
  //     <br />

  //     Cookies are small text files that are placed on your device when you visit a website. They can serve various purposes, including remembering your preferences or helping websites function efficiently. However, we have chosen not to utilize any cookies that collect commercial or statistical data.
  //     <br />

  //     **3. Enjoy Browsing Without Tracking**
  //     <br />

  //     Rest assured that when you visit our website, your online activity remains private. We do not track your behavior for advertising purposes, and we do not gather statistical insights about your browsing habits.
  //     <br />

  //     **4. Our Commitment to Privacy**
  //     <br />

  //     Your privacy is in good hands at AnisJerbi.com. We are dedicated to providing you with a safe and anonymous browsing experience, allowing you to explore our content without any intrusive data collection.
  //     <br />

  //     **5. Contact Us**
  //     <br />

  //     If you have any questions or concerns about your privacy while using our website, please feel free to contact us at info@anisjerbi.com. We are here to address any queries you might have.
  //     <br />

  //     Thank you for choosing AnisJerbi.com. Enjoy your visit knowing that your privacy is respected and protected throughout your browsing journey.
  //   </div>
  //     `,
  //     confirmButtonText:"I understand",
  //     confirmButtonColor:"var(--accents)",
  //   })
  // }

  return (
    <div className="App">
      <BrowserRouter>
        <AdminContextProvider>
          <Header />
{/* 
          <CookieConsent
            buttonText={"Close"}
            buttonStyle={{backgroundColor:"var(--accents)", color:"white"}}            
            containerClasses='cookieConsent'
          >
            <span style={{marginRight:"0.3rem"}}>Your privacy is secure with us as this website refrains from using commercial or statistical cookies.</span>
            <span onClick={() => showCookie()}><u>Full cookie policy</u></span>
          </CookieConsent> */}

          <Routes>
            <Route exact index element={<Home storageRef={storageRef} />} />
            <Route exact path='/contacts' element={<Contacts />} />
            <Route exact path='/gallery' element={<Gallery storageRef={storageRef} />} />
            <Route exact path='/press' element={<Press />} />
            <Route exact path='/admin' element={<TokenReq />} />
            <Route exact path='/tokenerror' element={<TokenError />} />
            <Route exact path='/admin/:token' element={<Admin storage={storage} />} />
          </Routes>

          <Footer />

        </AdminContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
