import React, { useEffect } from 'react'
import { UseAdminContext } from '../../contexts/AdminContext'
import { useNavigate } from 'react-router-dom'
import testAdminToken from '../../lib/testAdminToken'

export default function TokenReq() {
    const { adminToken } = UseAdminContext()

    const navigate = useNavigate()

    const generatetoken = () => {
        fetch(process.env.REACT_APP_API_URL + "/security", {
            method: "PATCH",
        })
            .then((res) => {
                if (res.ok)
                    alert("email inviata, controlla la tua mail")
                else
                    alert("errore")
            })
            .catch((err) => {
                alert("fatal error")
                console.log(err)
            })
    }

    useEffect(() => {
        if (adminToken || sessionStorage.getItem("adminToken")) {
            const test = async () => {
                const ok = await testAdminToken(adminToken ?? sessionStorage.getItem("adminToken"))
                if (ok) {
                    navigate("/admin/" + (adminToken ?? sessionStorage.getItem("adminToken")))
                }
            }

            test()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='TokenReq'>
            <div className="">
                Ti arriverà nella tua email un token temporaneo per loggarti come admin
            </div>
            <button
                onClick={() => {
                    generatetoken()
                }}
            >
                GENERA TOKEN
            </button>
        </div>
    )
}
