import React, { useState, useEffect } from 'react';
import './Gallery.scss';
import { ref, getDownloadURL } from 'firebase/storage';
import Image from './Image';
import Placeholder from './Placeholder';
import { UseAdminContext } from '../../contexts/AdminContext';
import testAdminToken from '../../lib/testAdminToken';


export default function Gallery({ storageRef }) {
  const [imagesDW, setImagesDW] = useState([]);
  const [imagesURLS, setImagesURLS] = useState([])
  const [loadedImageCount, setLoadedImageCount] = useState(12);
  const [adminTokenOk, SETadminTokenOk] = useState(false);
  const [showMoreIcon, SETshowMoreIcon] = useState(false);
  const { adminToken } = UseAdminContext()


  let timeoutId; // Variable to store the timeout ID



  const handleClickImg = (e) => {
    const target = e.currentTarget;
    if (target.classList.contains('image-selected')) {
      target.classList.remove('image-selected');
    } else {
      document.querySelectorAll('.image').forEach((i) => i.classList.remove('image-selected'));
      target.classList.add('image-selected');
    }
  };

  
  useEffect(() => {
    const myfetch = async () => {
      const imagesjson = await fetch(process.env.REACT_APP_API_URL + '/paintings')
      const images = await imagesjson.json()
      setImagesURLS(images)
    }

    myfetch()

  }, [])

  useEffect(() => {
    const loadImageData = async () => {
      const updatedImages = await Promise.all(
        imagesURLS.slice(0, loadedImageCount).map(async (i) => {
          try {
            const imagesRef = ref(storageRef, i.img);
            const url = await getDownloadURL(imagesRef);
            return { img: url, n: i.name, a: i.available, id: i.id };
          } catch (error) {
            return null;
          }
        })
      );
      const oldimgs = imagesDW.length
      setImagesDW(updatedImages.filter((img) => img !== null));
      SETshowMoreIcon(oldimgs !== updatedImages.filter((img) => img !== null).length)
    };


    if (imagesURLS)
      loadImageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesURLS, loadedImageCount, storageRef]);


  // Scroll event handler to detect when the user reaches the bottom
  const handleScroll = () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.scrollHeight;
      const scrollPosition = window.scrollY;

      if (bodyHeight - (scrollPosition + windowHeight) < 100) {
        setLoadedImageCount((prevCount) => prevCount + 12);
      }
    }, 200); // Adjust the debounce delay as needed
  };

  // Add scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutId]);


  useEffect(() => {
    const test = async () => {
      if (adminToken) {
        const ok = await testAdminToken(adminToken)
        SETadminTokenOk(ok)
      }
    }

    test()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (imagesURLS.length === 0)
    return (
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px"
        }}
      >
        <Placeholder />
      </div>
    )


  return (<>
    <div className="gallery">
      {imagesDW.map((img, k) => {
        return (
          <div key={k} className="image" onClick={handleClickImg} >
            <Image img={img} showID={adminTokenOk} />
          </div>
        )
      }
      )}
    </div>
    {
      showMoreIcon &&
      <div
        className='morecoming'>
        <Placeholder/>
      </div>
    }
  </>
  );
}
