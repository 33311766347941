import React, { useState } from 'react';
import './Contacts.scss';
import axios from 'axios';
import Swal from 'sweetalert2';

const Contacts = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailcontent, setEmailContent] = useState('');

  const sendMail = async () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (email && emailcontent && name && emailRegex.test(email))
      try {
        await axios.post('https://api.emailjs.com/api/v1.0/email/send', {
          service_id: process.env.REACT_APP_EMAILJS_SERVICE,
          template_id: process.env.REACT_APP_EMAILJS_TEMPLATE,
          user_id: process.env.REACT_APP_EMAILJS_USER,
          template_params: {
            name_client: name,
            email_client: email,
            text_client: emailcontent,
          },
        });

        setName('');
        setEmail('');
        setEmailContent('');

        Swal.fire({
          icon: "success",
          text: 'Your message was sent successfully'
        });
      } catch (error) {
        console.error(error);
      }
    else {
      Swal.fire({
        icon: "error",
        text: 'Unexpected error while sending message, if the problem persists please contact our support team at info@ojxwebdev.com'
      })
    }
  };

  const textAreaAdjust = (element) => {
    element.style.height = '1px';
    element.style.height = 25 + element.scrollHeight + 'px';
  };

  return (
    <div className="contacts">
      <div className="fstrow">
        <img src="https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2FContact%20Seal.jpg?alt=media&token=06b16af7-c772-4113-9a9c-16489d80af7a" alt="" srcset="" />

        <form className="formcontact">
          <h1>Reach out to me:</h1>
          <div>
            <input
              value={name}
              type="text"
              placeholder="Your name..."
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              value={email}
              type="text"
              placeholder="Your email address..."
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <textarea
              value={emailcontent}
              onKeyUp={(e) => textAreaAdjust(e.target)}
              rows={'10'}
              cols="30"
              placeholder="How may I help you?"
              onChange={(e) => setEmailContent(e.target.value)}
            />
          </div>
          <div className="button-wrapper">
            <button onClick={(e) => { e.preventDefault(); sendMail(); }}>Send message</button>
          </div>
        </form>
      </div>

      <div className="more">
        <h3>If you have specific and detailed business inquiries, please feel free to contact me via email at:</h3>
        <ul>
          <li><a href="mailto:aj@anisjerbi.com">aj@anisjerbi.com</a></li>
          <li><a href="mailto:contact@anisjerbi.com">contact@anisjerbi.com</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Contacts;
