import React, { useState, useEffect } from 'react';
import './Header.scss';
import { Link, useLocation } from 'react-router-dom';
import { UseAdminContext } from '../../contexts/AdminContext'


// changelink in email token


function Header() {
    const [burgerOpen, setBurgerOpen] = useState(false);
    const { adminToken } = UseAdminContext()
    const location = useLocation();

    const setSelectedLink = (e) => {
        document.querySelectorAll('.link').forEach((l) => {
            l.classList.remove('selected-link');
        });

        e.currentTarget.classList.add('selected-link');
    };

    useEffect(() => {
        const scrollHandler = () => {
            if (burgerOpen) {
                window.scrollTo(0, 0);
            }
        };
        document.addEventListener('scroll', scrollHandler);
        return () => {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, [burgerOpen]);

    return (
        <div className="header">
            <div
                className="burger-menu"
                onClick={() => {
                    const toShow = document.querySelector('#toshow');
                    if (toShow.classList.contains('menu-showing')) {
                        toShow.classList.remove('menu-showing');
                        setBurgerOpen(false);
                    } else {
                        toShow.classList.add('menu-showing');
                        setBurgerOpen(true);
                    }
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>

                <div className="menu" id="toshow">
                    <div className={`link ${location.pathname === '/' ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                        <Link to="/">Home</Link>
                    </div>
                    <div className={`link ${location.pathname.includes('gallery') ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                        <Link to="/gallery">Portfolio</Link>
                    </div>
                    <div className={`link ${location.pathname.includes('contacts') ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                        <Link to="/contacts">Contacts</Link>
                    </div>

                    {adminToken &&
                        <div className={`link ${location.pathname.includes('admin') ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                            <Link to={"/admin/"+adminToken}>Admin</Link>
                        </div>
                    }

                </div>
            </div>

            <div className="desktop-menu">
                <div className="left">
                    <div className={`img-wrapper link${location.pathname === '/' ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                        <Link to="/">
                            <img src="/img/logo.png" alt="" />
                        </Link>
                    </div>
                </div>

                <div className="right">
                    <div className={`link ${location.pathname.includes('gallery') ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                        <Link to="/gallery">Portfolio</Link>
                    </div>
                    <div className={`link ${location.pathname.includes('contacts') ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                        <Link to="/contacts">Contacts</Link>
                    </div>
                    {adminToken &&
                        <div className={`link ${location.pathname.includes('admin') ? ' selected-link' : ''}`} onClick={(e) => setSelectedLink(e)}>
                            <Link to={"/admin/"+adminToken}>Admin</Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Header;
