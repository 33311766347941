import React, { useRef, useEffect, useState } from 'react';
import './AnimImage.scss'

export default function AnimImage({ src, style }) {
    const imgRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleIntersection = (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // use the viewport as the root
            rootMargin: '0px', // no margin
            threshold: 0.1, // 10% of the element is visible
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(imgRef.current);
            }
        };
    }, []);
    return (
        <div className={"img animated-image " + (isVisible && 'visible')}
            ref={imgRef}
        >
            <img
                style={style}
                src={src} alt="" />
        </div>
    )
}
