/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import testAdminToken from '../lib/testAdminToken'
import { useNavigate } from "react-router-dom";

const AdminContext = createContext()

const AdminContextProvider = ({ children }) => {
    const [adminToken, SETadminToken] = useState(null)
    const navigate = useNavigate()

    const testToken = () => {
        const timeoutId = setTimeout(async () => {
            if (adminToken) {
                const ok = await testAdminToken(adminToken);
                if (ok) {
                    console.log("token ok")
                }
                else {
                    sessionStorage.removeItem("adminToken")
                    SETadminToken(null)

                    navigate("/tokenerror")
                }
            }
            testToken()
        }, 15 * 60 * 1000)

        return () => {
            // Clear the timeout when the component unmounts
            clearTimeout(timeoutId);
        };
    }


    useEffect(() => {
        testToken()
        if (adminToken)
            sessionStorage.setItem("adminToken", adminToken)

        // Cleanup function to clear the timeout when the component unmounts
        return () => {
            clearTimeout();
        };
    }, [adminToken])

    useEffect(() => {
        if (sessionStorage.getItem("adminToken"))
            SETadminToken(sessionStorage.getItem("adminToken"))
    }, [])

    return (
        <AdminContext.Provider value={{ adminToken, SETadminToken }}>
            {children}
        </AdminContext.Provider>
    )
}

export default AdminContextProvider
export const UseAdminContext = () => useContext(AdminContext)