import React, { useState } from 'react';
import Placeholder from './Placeholder';

const Image = ({ img, showID = false }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div
            className={`loadingwrapper ${loaded ? 'loaded' : ''}`}
        >
            {!loaded && <Placeholder />}

            <img
                src={img.img}
                alt={"alt"}
                style={{ display: loaded ? 'block' : 'none' }}
                onLoad={() => setLoaded(true)}
            />

            {
                loaded && <>
                    <div className="title">{img.n}</div>
                    {!img.a && <div className="ribbon">SOLD</div>}
                    {showID && <div className="imgid">{img.id}</div>}
                </>
            }
        </div>
    );
};

export default Image;