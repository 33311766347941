/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import "./Card.scss"


export default function Card({ classname, title, content, style }) {
    const paragraphRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleIntersection = (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // use the viewport as the root
            rootMargin: '0px', // no margin
            threshold: 0.1, // 20% of the element is visible
        });

        if (paragraphRef.current) {
            observer.observe(paragraphRef.current);
        }

        return () => {
            if (paragraphRef.current) {
                observer.unobserve(paragraphRef.current);
            }
        };
    }, []);

    
    return (
        <div
            className={'Card ' + classname + (` animated-paragraph ${isVisible ? 'visible' : ''}`)}
            style={style}
            ref={paragraphRef}
        >
            <div className="title special">
                {title}
            </div>
            <article>
                {content}
            </article>
        </div>
    )
}
