import React, { useState, useEffect } from 'react';
import './Home.scss';
import Card from '../Card/Card';
import { Link } from 'react-router-dom';
import AnimImage from '../AnimImage/AnimImage';
import { ref, getDownloadURL } from 'firebase/storage';

export default function Home({ storageRef }) {
  const [imagesURLS, setImagesURLS] = useState([])
  const [bwImg, SETbwImg] = useState("")


  function getRandomIntInclusive(min, max) {
    min = Math.ceil(min); // Make sure the minimum value is inclusive
    max = Math.floor(max); // Make sure the maximum value is inclusive
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  const getImg = async () => {
    const r = getRandomIntInclusive(0, imagesURLS.length)
    const imagesRef = ref(storageRef, imagesURLS[r].img);
    const url = await getDownloadURL(imagesRef);


    SETbwImg(url)
  }

  useEffect(() => {
    const myfetch = async () => {
      const imagesjson = await fetch(process.env.REACT_APP_API_URL + '/paintings')
      const images = await imagesjson.json()
      setImagesURLS(images)
    }

    myfetch()

  }, [])

  return (
    <div className="home">
      <div className="hero">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2FOfficial%20photo%20of%20oil%20painting%20of%20Taylor%20Swift.jpg?alt=media&token=07782c3e-d5eb-4e5f-b29f-ddb1995a82b9"
          alt=""
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2FOfficial%20photo%20of%20drawing%20of%20Rayna.jpg?alt=media&token=49c5f1ef-85ff-483e-b442-3c3e0922e973"
          alt=""
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2FOil%20painting%20of%20AOC.jpg?alt=media&token=a6b25849-1498-4eef-bb3b-f18779a2d6e0"
          alt=""
        />
        <h1 className="special">
          <span id="title"
            onMouseEnter={() => getImg()}
            style={{
              backgroundImage: `url(${bwImg})`
            }}>
            <font>
              Paintings, Drawings & everything in between.
            </font>
          </span>
        </h1>
      </div>

      <div className="subnav">
        <nav>
          <Link to={"/gallery"}>Check out my works
            <hr />
          </Link>
          <Link to={"/press"}>Press section
            <hr />
          </Link>
        </nav>
      </div>

      <section className="container">
        <article className="cardsection">
          <Card
            style={{ borderColor: "var(--spec2)" }}
            title={"Who I am:"}
            content={
              <div>
                <p>
                  My name is Anis Jerbi, I'm a twenty-year-old, self-taught artist. I was born in and currently live in Tunisia. I started drawing when I was thirteen years old and I quickly fell in love with art.
                </p>
                <p>
                  By the time I was fifteen, I had already started painting. I began with acrylics, but I quickly moved to oils a year later at the age of sixteen. I never had any formal training in art. I've learned everything I know so far when it comes to my craft by practicing myself. I will, however, start studying at the fine arts academy of Verona very soon and I cannot wait to start making steps in the art world more formally.
                </p>
                <p>
                  Throughout the years, I've already gained notoriety as a young artist on the internet garnering over one hundred thousand followers on Instagram, selling many of my artworks to clients worldwide and appearing on many articles and digital magazines already. Here you can see all of my artworks. Do not hesitate to contact me to commission an artwork from me or purchase one of my artworks!
                </p>
              </div>
            }
          />
          <AnimImage src={"https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2FWho%20I%20am.jpg?alt=media&token=35d65760-38cf-4466-9693-8216020eac79"} />
        </article>

        <article className="cardsection">
          <Card
            style={{ borderColor: "var(--spec1)" }}
            title={"My ambitions:"}
            content={
              <div>
                <p>
                  I've learned that with enough work, nothing is impossible and I don't mind working hard to reach my goals. I cannot wait to study at the fine arts academy in Verona and learn a lot about art and the art world. My dream is to become a world-renowned painter and I will do everything in my power to make my dream come true.
                </p>
                <p>
                  When I started painting however, I took a lot of inspiration from Andrew Tischler, Chuck Black and Florent Farges as well. They're contemporary painters and I'm a huge admirer of their work. I've learned a lot of techniques as well as tricks to paint from them. I take a lot of inspiration as a person and public figure from Taylor Swift as well. I find her kindness and humility admirable.
                </p>
              </div>
            }
          />
          <AnimImage src={"https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2FMy%20ambitions.jpg?alt=media&token=594c240b-9342-4837-9dc8-83cb751e9475"} />
        </article>

        <article className="cardsection">
          <Card
            style={{ borderColor: "var(--spec4)" }}
            title={"My inspirations:"}
            content={
              <div>
                <p>
                  I've learned that with enough work, nothing is impossible and I don't mind working hard to reach my goals. I cannot wait to study at the fine arts academy in Verona and learn a lot about art and the art world. My dream is to become a world-renowned painter and I will do everything in my power to make my dream come true.
                </p>
                <p>
                  To me, art is not just a hobby or a job, it's my life and as long as I can breathe, I will also paint and draw.
                </p>
              </div>
            }
          />

          <AnimImage style={{ transform: "rotate(45deg)", scale: "2" }} src={"https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2FMy%20inspirations.jpg?alt=media&token=4544e1ea-9728-47b2-93b3-2ed1301f3f99"} />

        </article>



        {/* <div className="shop">
          Check out my designs on my <a target='_blank' href="https://www.redbubble.com/people/anis-jerbi-art/shop" rel="noreferrer">REDBUBBLE</a> online shop
        </div> */}

        {/* <div className="latest">
          My latest work
        </div> */}
      </section>




      <div className="donations">
        <a href="https://www.paypal.me/anisjerbi" target={'_blank'} rel="noreferrer">
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4.7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9.7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z" /></svg>
            Support me with a one-time donation
          </button>
        </a>

        <a href="https://www.patreon.com/Anis_Jerbi_art" target={'_blank'} rel="noreferrer">
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 194.8c0 101.3-82.4 183.8-183.8 183.8-101.7 0-184.4-82.4-184.4-183.8 0-101.6 82.7-184.3 184.4-184.3C429.6 10.5 512 93.2 512 194.8zM0 501.5h90v-491H0v491z" /></svg>
            Support me with a monthly donation
          </button>
        </a>
      </div>
    </div>
  );
}

