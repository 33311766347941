import React from 'react'
import './Article.scss'

export default function Article({ img, tit }) {


    return (
        <div className="article">
            <div className="img">
                {img}
            </div>
            <div className="tit">{tit}</div>
        </div>
    )
}

