import React from 'react'
import Article from './Article'
import './Press.scss'

export default function Press() {
  return (
    <div className="press">
      <div className="title">
        <h1>
          Press Articles and Interviews Where I Was Featured
        </h1>
      </div>

      <div className="articles">

        <Article
          img={
            <img src={'https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2FCattura.JPG?alt=media&token=35622c4e-1ef9-4729-8d94-b0ad7b6a8f05'} alt={''} />
          }
          tit={<a href="https://www.voanews.com/amp/bypassing-digital-iron-curtain-activists-message-millions-in-russia/6511104.html" target={'_blank'} rel="noreferrer">
            https://www.voanews.com/amp/bypassing-digital-iron-curtain-activists-message-millions-in-russia/6511104.html
          </a>}
        />

        <Article
          img={
            <img src={'https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2FpressImg.jpeg?alt=media&token=44c7dcac-67ba-499d-86ec-1a6a4f36137b'} alt={''} />
          }
          tit={<a href="https://omny.fm/shows/philippe-vincent-foisy/twitter-je-me-suis-abonn-le-14-d-cembre-et-il-ne-m" target={'_blank'} rel="noreferrer">
            https://omny.fm/shows/philippe-vincent-foisy/twitter-je-me-suis-abonn-le-14-d-cembre-et-il-ne-m
          </a>}
        />

        <Article
          img={
            <img src={'https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2Fpress_melange.jpeg?alt=media&token=40216e89-70bf-43de-b63e-1a434ebb88db'} alt={''} />
          }
          tit={<a href="https://viewer.joomag.com/m%C3%A9lange-accessibility-for-all-magazine-spring-2023/0201495001682996624?short&" target={'_blank'} rel="noreferrer">
            https://viewer.joomag.com/m%C3%A9lange-accessibility-for-all-magazine-spring-2023/0201495001682996624?short&
          </a>}
        />



        <Article
          img={
            <img src={'https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2Fpress_imdb.jpeg?alt=media&token=3108c4e4-10b6-44da-8e2d-d60beba58a7d'} alt={''} />
          }
          tit={<a href="https://m.imdb.com/name/nm11272610/" target={'_blank'} rel="noreferrer">
            https://m.imdb.com/name/nm11272610/
          </a>}
        />


        <Article
          img={
            <img src={'https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2Fstatic%2Fpress_nuov.jpeg?alt=media&token=1a03f3a1-7d79-4a96-be63-dbe51115cdd6'} alt={''} />
          }
          tit={<a href="https://www.lenouvelliste.ca/chroniques/isabelle-legare/2023/07/23/ladmiration-dun-medecin-pour-son-patient-exceptionnel-PB6VPVMOAVESVH3C2R5EOCLV74/" target={'_blank'} rel="noreferrer">
            https://www.lenouvelliste.ca/chroniques/isabelle-legare/2023/07/23/ladmiration-dun-medecin-pour-son-patient-exceptionnel-PB6VPVMOAVESVH3C2R5EOCLV74/
          </a>}
        />
      </div>

    </div>
  )
}

