import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UseAdminContext } from '../../contexts/AdminContext'
import { ref, uploadBytes, deleteObject } from 'firebase/storage';
import "./Admin.scss"

export default function Admin({ storage }) {
    const params = useParams()
    const [allowed, SETallowed] = useState(false)
    const [imgBlob, SETimgBlob] = useState(null)
    const [imageName, SETimageName] = useState("")
    const [isLoading, SETisLoading] = useState(false)

    const { SETadminToken } = UseAdminContext()
    const navigate = useNavigate()


    const [ins, SETins] = useState({
        name: "",
        desc: "",
        img: "",
        price: 0,
        available: true
    })

    const [idDelete, SETidDelete] = useState("")

    const [updt, SETupdt] = useState({
        id: "",
        name: "",
        available: true
    })

    useEffect(() => {
        SETisLoading(true)
        const test = async () => {
            const res = await fetch(process.env.REACT_APP_API_URL + '/security', {
                method: "POST",
                body: JSON.stringify({ token: params.token }),
                headers: {
                    "content-type": "application/json"
                }
            })
            SETallowed(res.ok)
            SETisLoading(false)

            if (res.ok) {
                SETadminToken(params.token)
            }
        }

        test()
    }, [SETadminToken, params.adminToken, params.token])

    const handleImgUpload = async () => {
        SETisLoading(true)

        const newimageRef = ref(storage, "img/" + imageName)
        uploadBytes(
            newimageRef,
            imgBlob
        ).then(async res => {
            await fetch(process.env.REACT_APP_API_URL + '/paintings', {
                method: "POST",
                body: JSON.stringify(ins),
                headers: {
                    "content-type": "application/json"
                }
            })

            SETisLoading(false)
            alert("immagine aggiunta corretamente")
        })
    }


    const handleDelete = () => {
        fetch(process.env.REACT_APP_API_URL + '/paintings', {
            method: "DELETE",
            body: JSON.stringify({ id: idDelete }),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(async (res) => {
                const deleted = await res.json()

                deleteObject(ref(storage, deleted.img))
                    .then(res => {
                        console.log("eliminato da db")
                        SETidDelete("")
                        alert("immagine eliminata corretamente")
                    })
                    .catch(res => {
                        console.log(res)
                    })

            })
            .catch((res) => {
                console.log(res)
            })
    }

    const handleUpdt = () => {
        fetch(process.env.REACT_APP_API_URL + '/paintings', {
            method: "PATCH",
            body: JSON.stringify(updt),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(async (res) => {
                const updted = await res.json()
                SETupdt({ id: "", available: true, name: "" })
                alert("immagine modificata corretamente")
                console.log(updted)
            })
            .catch((res) => {
                console.log(res)
            })
    }


    if (isLoading)
        return (
            <>
                Loading...
            </>
        )
    return (
        <div className='Admin'>
            {
                allowed ?
                    <>
                        <form>
                            <h2>Aggiungi dipinto:</h2>
                            <div>
                                <input type="text" placeholder='Titolo' onChange={(e) => SETins({ ...ins, name: e.target.value })} />
                            </div>
                            <div>
                                <input type="text" placeholder='Descrizione' onChange={(e) => SETins({ ...ins, desc: e.target.value })} />
                            </div>
                            <div>
                                <input type="text" placeholder='Prezzo' onChange={(e) => SETins({ ...ins, price: e.target.value })} />
                            </div>
                            <div>
                                Disponibile:
                                Si<input type="radio" name="available" onChange={(e) => SETins({ ...ins, available: true })} />
                                No<input type="radio" name="available" onChange={(e) => SETins({ ...ins, available: false })} />
                            </div>
                            <div>
                                <input type="file" onChange={(e) => {
                                    const selectedFile = e.target.files[0];

                                    if (selectedFile) {
                                        SETimageName(selectedFile.name)
                                        SETins({ ...ins, img: ("img/" + selectedFile.name) })
                                        const blob = new Blob([selectedFile], { type: selectedFile.type });
                                        SETimgBlob(blob)
                                    }
                                }}
                                />
                            </div>
                            <div>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleImgUpload()
                                    }}>
                                    Salva
                                </button>
                            </div>
                        </form>

                        <form>
                            <h2>Modifica dipinto:</h2>
                            <div>
                                <input value={updt.id} type="text" placeholder='ID dipinto da modificare' onChange={(e) => SETupdt({ ...updt, id: e.target.value })} />
                            </div>
                            <div>
                                <input value={updt.name} type="text" placeholder='Nuovo titolo' onChange={(e) => SETupdt({ ...updt, name: e.target.value })} />
                            </div>
                            <div>
                                Disponibile:
                                Si<input type="radio" checked name="available" onChange={(e) => SETupdt({ ...updt, available: true })} />
                                No<input type="radio" name="available" onChange={(e) => SETupdt({ ...updt, available: false })} />
                            </div>
                            <div>
                                <button
                                    onClick={(e) => { e.preventDefault(); handleUpdt() }}
                                >Modifica</button>
                            </div>
                        </form>

                        <form>
                            <h2>Elimina dipinto:</h2>
                            <div>
                                <input value={idDelete} type="text" placeholder='ID dipinto da eliminare' onChange={(e) => SETidDelete(e.target.value)} />
                            </div>
                            <div>
                                <button
                                    onClick={(e) => { e.preventDefault(); handleDelete() }}
                                >Elimina</button>
                            </div>
                        </form>

                        <form>
                            <h2>Logout Admin:</h2>
                            <div>
                                <button id='logout' onClick={(e) => {
                                    e.preventDefault()
                                    SETadminToken(null)
                                    sessionStorage.removeItem('adminToken')
                                    navigate("/")
                                    window.location.reload()
                                }}>Logout</button>
                            </div>
                        </form>
                    </>
                    : <>
                        Not allowed
                    </>
            }
        </div>
    )
}
