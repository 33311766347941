import React from 'react'

export default function TokenError() {
    return (
        <div className='TokenError'>
            <h1>Token admin scaduto</h1>
            <h2>Ri-effettua l'accesso</h2>
        </div>
    )
}
